@import "mixin.scss";

html{
  overflow-x: hidden;
}

body {
  background: linear-gradient(
    235deg,
    #f48101,
    #f4521e,
    #f91d33,
    #e3003f,
    #900047,
    #630232
  );
  background-size: 360% 360%;
  animation: gradient-animation 12s ease infinite;
  width: 100%;
}
h1 {
  font-size: 45px;
  color: $white;
  margin: 0;
  position: relative;
  z-index: 2;
  font-family: "Druk Text Wide";
  @include small-desktop{
    font-size: 40px;
  }
  @include media-mobile {
    font-size: 22px;
    padding: 1.5rem 0 0 0;
  }
}
h2 {
  font-size: 30px;
  margin: 0;
  position: relative;
  z-index: 2;
  font-family: "Druk Text Wide";

  @include small-desktop{
    font-size: 26px;
  }

  @include media-mobile {
    font-size: 22px;
  }
}
p {
  font-size: 20px;
  color: $white;
  margin: 0;
  position: relative;
  z-index: 2;
  font-family: Graphik;
  @include small-desktop{
    font-size: 18px;
  }
  @include media-mobile {
    font-size: 16px;
  }
  line-height: 1.3em;
}

img {
  z-index: 3;
  position: relative;
}

a.btn:hover{
  p{
    text-decoration: underline;
  }
}

a.btn {
  all: unset;
  position: relative;
  z-index: 2;
  border: 3px solid #fefefe;
  padding: 1.5rem 1.5rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  p {
    font-size: 15px;
    text-transform: uppercase;
    color: $white;
    letter-spacing: 4.5px;
    text-align: center;
  }
}
.mix-blend-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  // mix-blend-mode: screen;
  overflow: hidden;
  max-height: 100vh;
}

@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.max-height {
  height: 100vh;
}

h6 {
  margin: 0;
}

.back {
  position: relative;
  z-index: 10;
}

.background-video {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  opacity: 1;
  width: 100%;
  height: 100%;
  mix-blend-mode: multiply;

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    mix-blend-mode: multiply;
    object-position: 50% 50%;
  }
}
.full-image {
  width: 100%;
  display: block;
}

.h1-child {
  max-width: 60%;
  padding-top: 50px;
  @include media-mobile {
    max-width: 80%;
    padding: 0 0 1.5rem 0;
  }
}

.pad {
  padding: 3rem 3rem 0 3rem;
  margin-bottom: -3rem;
  display: flex;
  flex-direction: column;
  width: min(1800px, 100%);
  @include media-mobile {
    padding: 0rem 1.5rem;
    margin-bottom: -1.5rem;
    h1 {
      padding-bottom: 1.5rem;
    }
  }
}
.no-bot {
  padding-bottom: 0;
}
.no-top {
  padding-top: 0;
}

.white {
  background-color: $white;
}
.borders {
  position: relative;
  z-index: 10;
}
.hero {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.accoms {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  position: relative;
  width: 100%;

  .park {
    h2{
      margin-top: 0;
    }
  }
  h2 {
    color: #ff0045;
    font-size: 35px;
    max-width: 100%;
    padding-left: 1.5rem;
    margin-left: 0;
    margin-top: -3rem;
    @include small-desktop{
      font-size: 30px;
    }
    @include media-mobile {
      margin-top: 0;
      font-size: 22px;
      padding: 0 1.5rem 1.5rem 1.5rem;
    }
  }
}


.text {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 90%;
  padding: 3rem 1.5rem;
  @include media-mobile {
    flex-direction: column;
    max-width: 100%;
    padding: 0rem 1.5rem 1.5rem 1.5rem;
  }

 
  p {
    font-family: "Graphik-Regular";
    font-size: 20px;
    color: black;
    max-width: 48%;
    padding: 0rem 0rem;
    margin-bottom: -3rem;
    @include small-desktop{
      font-size: 16px;
    }
    @include media-mobile {
      margin-bottom: 0;
      font-size: 15px;
      max-width: 100%;
      padding: 0;
    }
    b {
      display: flex;
      max-width: 70%;
      @include media-mobile {
        max-width: 100%;
        padding: 0 0 1.5rem 0;
      }
    }
  }
}
.text.loc{
  p{
    margin: 0;
  }
}
.swiper-padder {
  padding: 10rem 5rem;
  @include media-mobile {
    padding: 0 1.5rem;
  }
}

.spec {
  position: relative;

  h2 {
    color: $white;
    padding: 0 0 5rem 3rem;
    position: relative;
    z-index: 2;
    @include media-mobile {
      padding: 0 1.5rem 1.5rem 1.5rem;
    }
  }
  div {
    z-index: 2;
  }
}
.title {
  padding: 10rem 3rem 1.5rem 3rem;
  margin-bottom: -5rem;
  @include media-mobile {
    padding: 6.5rem 1.5rem 0rem 1.5rem;
    margin-bottom: 0rem;
  }
  h1 {
    position: relative;
    z-index: 2;
    padding: 0 0 3rem 0;
    @include media-mobile {
      padding: 0 0 1.5rem 0;
    }
  }
  p {
    position: relative;
    z-index: 2;
    padding: 0 0 1.5rem 0;
    max-width: 40%;
    font-family: "Graphik";
    @include media-mobile {
      max-width: 100%;
      padding: 0;
      font-size: 15px;
    }
  }
}

.map {
  height: 80vh;
  padding: 3rem 3rem;

  @include media-mobile {
    height: 50vh;
    padding: 1.5rem 1.5rem;
  }
}
.map-back {
  background-color: white;
}
.loc-content {
  h2 {
    padding: 5rem 3rem 1.5rem 3rem;
    @include media-mobile {
      padding: 1.5rem 1.5rem;
    }
  }
  .left-text {
    p {
      color: $light-black;
      font-size: 18px;
      max-width: 50%;
      padding-left: 3rem;
      font-family: "Graphik-Regular";

      @include small-desktop{
        font-size: 16px;
      }

      @include media-mobile {
        font-size: 15px;
        max-width: 100%;
        padding: 0 1.5rem 1.5rem 1.5rem;
      }
    }
  }
}
.flex-box {
  display: flex;
  align-items: center;
  @include media-mobile {
    flex-direction: column-reverse;
  }
}
.triangle {
  display: block;
  width: 100%;
}
.triangle-180 {
  display: block;
  width: 100%;
  transform: rotate(180deg);
}
.triangle-flipped {
  display: block;
  width: 100%;
  transform: scaleY(-1);
  background: linear-gradient(to bottom right, transparent 50%, white 50%);
}
.triangle-flipped-x {
  display: block;
  width: 100%;
  transform: scaleX(-1);
}

.logo {
  position: relative;
  z-index: 53;
}

@include media-desktop {
  .desk-pad {
    padding-bottom: 3rem;

    p {
      margin-bottom: 0;
    }
  }
}

@include media-tablet {
  .desk-pad {
    padding-bottom: 3rem;

    p {
      margin-bottom: 0;
    }
  }
}
