*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
 }

@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin contain{
  max-width: 2000px;
  width: 100%;
}

@mixin clearfix {
  &:before,
  &:after {
    content: "";
    display: table;
  }
  &:after {
    clear: both;
  }
}

@mixin ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin transition($properties, $duration: 0.3s, $timing-function: ease) {
  transition: $properties $duration $timing-function;
}

@mixin box-shadow($x: 0, $y: 0, $blur: 0, $spread: 0, $color: black) {
  box-shadow: $x $y $blur $spread $color;
}
@mixin media-mobile {
  @media (max-width: 767px) {
    @content;
  }
}

@mixin media-tablet {
  @media (min-width: 768px) and (max-width: 1023px) {
    @content;
  }
}

@mixin small-desktop {
  @media (min-width: 1024px) and (max-width: 1279px) {
    @content;
  }


}

@mixin media-desktop {
  @media (min-width: 1024px) {
    @content;
  }
}

.contain-wrap{
  width: 100%;
  @include flex-center;
  position: relative;
  flex-direction: column;
}

.contain {
  width: min(1800px, 100%);
}

@font-face {
  font-family: 'Druk Text Wide';
  src: url('../../public/fonts/DrukText/Bold/DrukTextWide-Bold.woff2') format('woff2'),
      url('../../public/fonts/DrukText/Bold/DrukTextWide-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'DrukTextWide-Medium';
  src: url('../../public/fonts/DrukText/Medium/DrukTextWide-Medium.woff2') format('woff2'),
      url('../../public/fonts/DrukText/Medium/DrukTextWide-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Graphik';
  src: url('../../public/fonts/Graphik/Medium/Graphik-Medium.woff2') format('woff2'),
      url('../../public/fonts/Graphik/Medium/Graphik-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Graphik-regular';
  src: url('../../public/fonts/Graphik/Regular/Graphik-Regular.woff2') format('woff2'),
      url('../../public/fonts/Graphik/Regular/Graphik-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Graphik-black';
  src: url('../../public/fonts/Graphik/black/Graphik-Black.woff2') format('woff2'),
      url('../../public/fonts/Graphik/black/Graphik-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}




$light-black: #4D4D4D;
$white: #fefefe;
