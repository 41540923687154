@import "../../scss/mixin.scss";

.travel-wrap {
  padding: 0rem 3rem;
  @include media-mobile {
    padding: 0rem 1.5rem;
  }
  width: 100%;
  h2 {
    padding-left: 0rem;
    font-size: 30px;
    width: 90%;
    max-width: 90%;
    @include media-mobile {
      font-size: 16px;
    }
  }

  p {
    font-size: 18px;
    @include media-mobile {
      font-size: 14px;
    }
    text-transform: uppercase;
    color: $light-black;
    width: 75%;
    padding: 5px 0;
    font-family: "Graphik-Regular";
    b {
      text-transform: lowercase;
      font-family: "Graphik-black";
    }
  }
  .content {
    display: flex;
    flex-wrap: wrap;
    img {
      width: 75px;
      padding: 1.5rem 0;
    }
    .icons {
      display: flex;
      width: 200px;
      flex-direction: column;
      max-width: 48%;
    }
  }
  .gradient-border {
    height: 2px;
    width: 90%;
    max-width: 90%;
    background: linear-gradient(
      235deg,
      #f48101,
      #f4521e,
      #f91d33,
      #e3003f,
      #900047,
      #630232
    );
    background-size: 360% 360%;
    animation: gradient-animation 12s ease infinite;
  }
}
