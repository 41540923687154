@import "../../scss/mixin.scss";
@include small-desktop{
    .icons-wrap{
        padding-left: 1.5rem;
    }
  }
.icons-wrap {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    flex-wrap: wrap;    
    div {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 3rem 3rem;
        width: 18%;
        @include media-mobile  {
            padding: 1rem 1.5rem ;
            width: 90%;
        }
        @include small-desktop{
            padding: 1rem 1.5rem;
            width: 30%;
          }
        @include media-tablet  {
            padding: 1rem 3rem;
            width: 90%;
        }
        img {
            width: 50px;
            height: 50px;
            margin: 0;
            padding-right: 1rem;
        }
    }
    

    p {
        font-size: 20px;
        font-family: 'Graphik-Regular';
        @include small-desktop{
            font-size: 16px;
          }
        @include media-mobile {
            font-size: 16px;
        }
    }
}

.icons-wrap.direction {
    flex-direction: column;
    @include media-mobile {
        padding: 1.5rem 0;
    }

    div {
        width: 100%;
        justify-content: flex-start;
        align-items: flex-start;
        @include media-mobile {
            width: 90%;
        }
        img {
            width: 100px;
            height: 100px;
        }
    }

    p {
        font-size: 18px;
        color: $light-black;
        width: 50%;
        @include media-mobile {
            font-size: 16px;
        }
    }
}
