@import "../../scss/mixin.scss";

.hero-dropdown {
    display: flex;
    
    padding: 3rem 1.5rem;
    width: 100%;
    gap: 40px;
    @include media-mobile{
        flex-direction: column;
        gap: 20px;
        padding: 0rem 1.5rem 3rem;
        justify-content: center;
        align-items: center;
    }
    p {
        color: black;
    }
    .floorplan-content {        
        display: flex;
        flex-direction: column;
        gap: 3rem;
        width: 30%;
        @include media-mobile{
            width: 100%;
            gap: 1.5rem;
        }
        .dropdown {
            .dropdown-title {
                h3 {
                    font-size: 22px;
                    @include media-mobile{
                        font-size: 18px;
                    }
                }
                p {
                    font-size: 16px;
                    @include media-mobile{
                        font-size: 14px;
                    }
                }
                &.purple {
                    background-color: #9a3794;
                }
                &.l-red {
                    background-color: #ee2d55;
                }
                &.red {
                    background-color: #ce181e;
                }
                &.yellow {
                    background-color: #eab010;
                }
            }
            .dropdown-content {
                padding: 1rem 0;
                display: flex;
                flex-direction: column;                
                width: 100%;     
                &.purple{
                    display: flex;
                    justify-content: space-between;
                    flex-direction: row;
                    padding: 1.5rem 1rem;
                }           
                .row{
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    padding: 1rem;
                    &:not(:first-child){
                        &.l-red{
                            border-top: 1px solid #ee2d55;
                        }
                        &.red{
                            border-top: 1px solid #ce181e;
                        }
                        &.yellow{
                            border-top: 1px solid #eab010;
                        }
                    }
                }
                p {
                    font-size: 16px;
                }
                &.purple {
                    p {
                        &:first-child {
                            color: #9a3794;
                        }
                    }
                }
                &.l-red {
                    p {
                        &:first-child {
                            color: #ee2d55;
                        }
                    }
                }
                &.red {
                    p {
                        &:first-child {
                            color: #ce181e;
                        }
                    }
                }
                &.yellow {
                    p {
                        &:first-child {
                            color: #eab010;
                        }
                    }
                }
            }

            .dropdown-title {
                display: flex;
                justify-content: space-between;
                width: 100%;
                color: $white;
                padding: 1rem;
                cursor: pointer;
                p {
                    color: white;
                }
            }
        }

        .multi-tenant {
            background-color: black;
            display: flex;
            justify-content: space-between;
            padding: 1rem;
            cursor: pointer;
            h3 {
                padding: 0rem 0rem;
                color: white;
                font-size: 22px;
                @include media-mobile{
                    font-size: 18px;
                }
            }
        }
    }
    .floorplan-image{
        width: 70%;
        padding-top: 0rem;
        max-width: 800px;
        @include media-mobile{
            width: 100%;
        }
        img{
            width: 100%;
        }
    }
}
