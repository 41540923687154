@import "../../scss/mixin.scss";

.nav-bar {
  background-color: rgba(128, 128, 128, 0.5);
  position: fixed;
  width: 100vw;
  z-index: 10;
  max-width: 100%;
  z-index: 50;
  @include flex-center;
  .nav-content {
    @include flex-center;
    justify-content: space-between;
    padding: 1rem 0;
    z-index: 2;
    @include contain;
    .nav-side {
      .nav-link.active {
        h6 {
          text-decoration: underline;
        }
      }
      @include flex-center;
      padding: 0 1.5rem;
      hr {
        height: 2px;
        background-color: white;
        border: 0;
        color: white;
        @include media-mobile {
          display: none;
        }
        @include media-tablet {
          display: none;
        }
      }
      a {
        color: white;
        text-decoration: none;
        padding: 0 1.5rem;
        &:hover {
          text-decoration: underline;
        }
        @include media-mobile {
          padding: 0 0rem;
        }
      }
    }

    h6 {
      font-variant: small-caps;
      font-size: 18px;
    }
    .nav-link {
      @include media-mobile {
        display: none;
      }
      @include media-tablet {
        display: none;
      }
    }

    .burger {
      display: none;
      @include media-mobile {
        display: flex;
      }
      @include media-tablet {
        display: flex;
      }
    }
  }
}

.line {
  color: white;
  @include media-mobile {
    display: none;
  }
}

.burger {
  @include media-desktop {
    display: none;
    display: relative;
  }
  z-index: 51;
}

.links {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 100%;

  div {
    padding: 0.5rem 0;
    a {
      color: white;
      text-decoration: none;
      font-size: 24px;
      font-family: "Druk Text Wide";
      position: relative;
      z-index: 52;
    }
  }
  div:hover {
    a {
      text-decoration: underline;
    }
  }
}

.burger-line {
  margin-bottom: 5px;
  height: 2px;
  background-color: white;
  color: white;
  content: " ";
  width: 20px;
}

.openbtn {
  position: fixed;
  top: 1.5rem;
}

.burger-menu {
  background: linear-gradient(
    235deg,
    #f48101,
    #f4521e,
    #f91d33,
    #e3003f,
    #900047,
    #630232
  );
  background-size: 360% 360%;
  animation: gradient-animation 12s ease infinite;
}
