@import "../../scss/mixin.scss";

.footer-hero {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    padding: 0rem 1.5rem;
    margin-top: -2rem;
    @include media-mobile {
        margin-top: 0;
        padding: 0 1.5rem;
    }
    h5 {
        font-family: "DrukTextWide-Medium";
        color: #ffffff;
        letter-spacing: -0.37px;
        line-height: 18px;
        font-size: 22px;
        padding: 1.5rem 0 3rem 0;
        position: relative;
        z-index: 2;
        @include media-mobile {
            padding: 0rem 0 1.5rem 0;
            font-size: 18px;
            color: #ffffff;
            letter-spacing: -0.34px;
            line-height: 1.3em;
            max-width: 70%;
        }
    }
}
.footer-wrapper {
    display: flex;
    justify-content: space-between;
    width: min(1800px, 100%);
    flex-wrap: wrap;
    .agents-wrap {
        flex-wrap: wrap;
        display: flex;
        justify-content: space-between;
        width: 400px;
        @include media-mobile {
            flex-direction: column;
        }
    }
    .agents-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        .agents {
            padding: 20px 0;
            @include media-mobile {
                padding: 1rem 0;
            }
        }
        p {
            position: relative;
            z-index: 2;
            font-size: 14px;
            padding: 2px 0;
            margin: 0;
            font-family: 'Graphik-Regular';
            @include media-mobile {
                font-size: 12px;
            }
            a {
                margin: 0;
                color: white;
            }
        }
        img {
            height: 100px;
            padding: 1rem 0;
        }
    }
}
.misrep {
    padding: 50px 0;
    width: min(1800px, 100%);
    p {
        position: relative;
        z-index: 2;
        font-size: 11px;
    }
}
.footer-btn {
    display: flex;
    flex-direction: column;
    padding: 1.5rem 0;
    a {
        margin: 0;
    }
}
