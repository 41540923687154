@import "../../scss/mixin.scss";

#swiper {
    padding: 2rem 3rem 1.5rem 3rem;

    width: 90%;

    @include media-mobile {
        max-width: 100%;
        padding: 1.5rem 1.5rem;
    }

    .swiper-slide {
        width: fit-content;

        @include media-mobile {
            img {
                max-width: 100%;
            }
        }
    }

    p {
        color: black;
        font-size: 14px;
        width: fit-content;
        padding-top: 5px
    }
    .swiper-pagination-bullet {
        border-radius: 0;
        content: url(../../../public/images/swiper/pag-inactive.svg);
        opacity: 1;
        background-color: white;
    }
    .swiper-pagination-bullet-active {
        border-radius: 0;
        content: url(../../../public/images/swiper/pag-active.svg);
    }
    .swiper-button-prev {
        content: url(../../../public/images/swiper/prev.svg);
        @include media-mobile {
            display: none;
        }
    }
    .swiper-button-next {
        content: url(../../../public/images/swiper/next.svg);
        @include media-mobile {
            display: none;
        }
    }
    .swiper-pagination {
        display: flex;
        justify-content: center;
        padding: 1rem 0 0 0;
        position: relative;
    }
}

#floorplanSwiper {
    .swiper-slide {
        display: flex;
        justify-content: center;
    }

    .img-wrapper {
        position: relative;
        width: min(800px, 100%);
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        img {
            width: 80%;
        }
    }
    .table {
        position: absolute;
        bottom: 5%;
        right: 0;
        z-index: 10;
        font-family: "Graphik-Regular";
        width: 45%;
        font-size: 12px;
        @include media-tablet {
            position: relative;
            padding-top: 100px;
            width: 80%;
            font-size: 12px;
        }
        @include media-mobile {
            position: relative;
            padding-top: 50px;
            width: 80%;
            font-size: 12px;
        }

        .flex-container {
            display: flex;
            flex-direction: column;
        }
        .flex-row {
            display: flex;
            padding-bottom: 3px;
            border-bottom: 2px solid #ff0045;
            &:first-child {
                font-weight: 700;
                text-transform: uppercase;
            }
        }

        .flex-cell {
            min-width: 100px;

            padding: 1px 0;
        }

        .flex-cell:nth-child(2) {
            min-width: 100px;
        }
        .flex-cell:nth-child(3) {
            min-width: unset;
        }
    }
    .two-col {
        .flex-cell {
            min-width: 50%;
        }
        .flex-cell:nth-child(2) {
            display: flex;
            justify-content: flex-end;
            width: 100%;
        }
    } .two-tables:nth-child(1) {
        translate: -110%;
        @include media-mobile {
            translate: unset;
        }
        @include media-tablet {
            translate: unset;
        }
    }
    .two-tables {
        width: 25%;
        .flex-cell {
            width: 100%;
        }
        .flex-cell:nth-child(2) {
            display: flex;
            justify-content: flex-end;
            width: 100%;
        }
        @include media-tablet {
            width: 100%;

            .flex-container {
                width: 90%;
            }
        }
        @include media-mobile {
            width: 100%;
            .flex-container {
                width: 90%;
            }
        }
    }
   
    .two-tables:nth-child(2) {
        .flex-cell {
            font-family: "Graphik-Regular";
            font-weight: 400;
            text-transform: capitalize;
        }
    }

    .swiper-pagination {
        display: flex;
        justify-content: center;
        padding: 1.5rem 0 0 0;
        position: relative;
    }

    .swiper-pagination-bullet {
        border-radius: 0;
        content: url(../../../public/images/swiper/pag-inactive.svg);
        opacity: 1;
        background-color: white;
    }
    .swiper-pagination-bullet-active {
        border-radius: 0;
        content: url(../../../public/images/swiper/pag-active.svg);
    }
    .swiper-button-prev {
        content: url(../../../public/images/swiper/prev.svg);
        @include media-mobile {
            display: none;
        }
    }
    .swiper-button-next {
        content: url(../../../public/images/swiper/next.svg);
        @include media-mobile {
            display: none;
        }
    }
}
